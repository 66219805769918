// Imports
import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect, useLocation } from "react-router-dom";
import { connect } from "react-redux";

// Component
function RoutePrivate(props) {
  const location = useLocation();
  return props.user.isAuthenticated ? (
    props.role ? (
      props.user.details.role === props.role ? (
        <Route {...props} component={props.component} />
      ) : (
        <Redirect to="/login" />
      )
    ) : (
      <Route {...props} component={props.component} />
    )
  ) : (
    location.pathname.startsWith("/reset-password") ?
      <Route {...props} component={props.component} />
      :
    <Redirect to="/login" />
  );
}

// Component Properties
RoutePrivate.propTypes = {
  user: PropTypes.object.isRequired,
};

// Component State
function routePrivateState(state) {
  return {
    user: state.user,
  };
}

export default connect(routePrivateState, {})(RoutePrivate);
