import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  CircularProgress as MuiCircularProgress, DialogActions, DialogContentText,
  Divider as MuiDivider,
  Paper as MuiPaper,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
//Material UI Components and Functions
import { makeStyles } from "@material-ui/core/styles";
import { spacing } from "@material-ui/system";
import moment from "moment";
// Redux Components
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { EditableTable } from "../../../components/EditableTable";
import { getAccountBalance, getAccountLedger } from "../../account/api/actions";
import { setSnackbar } from "../../snackbar/api/snackbar";
import {
  createNewAccountPayment,
  getAllAccountPayments,
  pushUpdateToCurrentlySelectedAccountPaymentsList,
  setPayments,
  setSelectedPayment,
  reverseAccountPayments
} from "../api/actions";
import PaymentForm from "./PaymentForm";
import SelectedPaymentInformationCard from "./PaymentCompleteInfoCard";

const Button = styled(MuiButton)(spacing);

const DashboardToolBarButton = styled(Button)`
  padding: 4px;
  min-width: 0;
  svg {
    width: 2em;
    height: 2em;
  }
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CircularProgress = styled(MuiCircularProgress)(spacing);

const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
  },
  percentDif: {
    textAlign: "right",
    color: "green",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loadingIcon: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

//Editable Table Object Expects: {objectArray, fieldTitles(Array), fieldTypes(Array), fieldNames(Array), handleRowClickParentMethod}
// Method can take line items if they exist initially or starts as an empty array of nothing passed
function AccountRecentPaymentsTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const navTo = (page) => history.push(page);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paymentFormOpen, setPaymentFormOpen] = React.useState(false);
  const [paymentDetailsOpen, setPaymentDetailsOpen] = React.useState(false);
  const [resetCachedData, setResetCachedData] = React.useState(true);
  const [reverseConfirmationOpen, setReverseConfirmationOpen] = React.useState(false);
  const [selectedPayment, setSelectedPayment] = React.useState({});

  const isAdmin = (props.user.details.administration || props.user.details.accounting || props.user.details.isSuperUser) ? true : false ;

  const PaymentRowStatusStyling = (payment) => {
    payment.totalPayment = payment.feeAmount + payment.paymentAmount;
    if (payment.status.includes("Success")) {
      return { backgroundColor: "#ccffc7" };
    }
    if (payment.status.includes("Pending")) {
      return { backgroundColor: "#feffc7" };
    }
    if (
      payment.status.includes("Cancelled") ||
      payment.status.includes("Rejected") ||
      payment.status.includes("Declined")
    ) {
      return { backgroundColor: "#ffc7c8" };
    }
    if (payment.paymentMethodTypeDesc == null) {
      payment.paymentMethodTypeDesc = payment.paymentObjectTypeDesc;
      if (payment.paymentObjectTypeDesc == null) {
        payment.paymentMethodTypeDesc = "Not Found";
      }
    }
    return {};
  };

  const getAllAccountPayments = () => {
    if (props.accountUuid && props.accountUuid != null) {
      props.getAllAccountPayments(
        page,
        rowsPerPage,
        props.accountUuid,
        true,
        resetCachedData
      );
    }
    else {
      if (!props.account.isInitialAccountLoading && props.account.initialAccount != null) {
        props.getAllAccountPayments(
          page,
          rowsPerPage,
          props.account.initialAccount.uuid,
          true,
          resetCachedData
        );
      }
    }
  };

  useEffect(() => {
    if (
      (page + 1) * rowsPerPage > props.payments.data.items.length ||
      resetCachedData
    ) {
      getAllAccountPayments();
    }
  }, [page, rowsPerPage, props.account.isInitialAccountLoading]);

  const handlePaymentFormClickOpen = () => {
    setPaymentFormOpen(true);
  };

  const handlePaymentFormClose = () => {
    getAllAccountPayments()
    setPaymentFormOpen(false);
  };

  const handlePaymentDetailsClose = () => {
    setPaymentDetailsOpen(false);
  };

  const handlePaymentDetailsOpen = () => {
    setPaymentDetailsOpen(true);
  };


  const updateLocalItemsValues = (items) => {
    // Replaces existing store object with new object with modified sub data
    props.setPayments(items);
  };

  // To be used to save over the api in the database
  const saveAllLineItems = (payments) => {
    if (payments.toUpdate.length > 0) {
      dispatch(setSnackbar(true, "warning", "Updating Account Payments"));
      props.reverseAccountPayments(payments.toUpdate).then(() => {
        props.getAccountBalance(props.account.data.account.id);
        getAllAccountPayments();
        props.getAccountLedger(props.account.data.account.accountNum);
        if (props.payments.error == null || props.payments.error == "") {
          dispatch(setSnackbar(true, "success", "Account ledger updated successfully"));
        }
        else {
          dispatch(setSnackbar(true, "error", "Error Updating Account Payments!"));
        }
      });
    }
  };

  const updateStateAndDB = () => {
    const {items} = selectedPayment
    updateLocalItemsValues(items);
    saveAllLineItems(selectedPayment);
    setReverseConfirmationOpen(false);
  }

  const handleConfirmReverseOpen = (payments) => {
    setSelectedPayment(payments)
    setReverseConfirmationOpen(true);
  }

  const handleConfirmReverseClose = () => {
    setReverseConfirmationOpen(false);
    setSelectedPayment({})
    getAllAccountPayments();
  }

  const fieldTitles = [
    "ID/Type",
    "Date/Status",
    "Payment/Fee",
    "Total",
    "Preview",
    "Reversed",
  ];
  const fieldTypes = [
    ["label", "label"],
    ["dateLabel"],
    ["moneyLabel", "moneyLabel"],
    "moneyLabel",
    "previewBtn",
    "switch"
  ];
  const fieldNames = [
    ["id", "paymentMethodTypeDesc"],
    ["paymentDate"],
    ["paymentAmount", "feeAmount"],
    "totalPayment",
    "",
    "reversed"
  ];

  const onPaymentSelected = (item) => {
    props.setSelectedPayment(item);
    handlePaymentDetailsOpen();
  };

  const handleChangeRowsPerPage = (rowsPerPage) => {
    setResetCachedData(false);
    setRowsPerPage(rowsPerPage);
  };

  const handleChangePage = (page) => {
    setResetCachedData(false);
    setPage(page);
  };

  const createNewPayment = () => {
    handlePaymentFormClickOpen();
  };

  return (
    <div>
      <EditableTable
        readOnly={true}
        canAddItems={isAdmin}
        canSave={false}
        // saveCurrentTableData={saveAllLineItems}
        updateItemsLocalState={handleConfirmReverseOpen}
        isLoading={props.payments.isLoading}
        conditionalStylingMethod={PaymentRowStatusStyling}
        // Functional methods of table
        newItemCreationMethod={handlePaymentFormClickOpen}
        customChangePageRowsEvent={handleChangeRowsPerPage}
        customHandleChangePageEvent={handleChangePage}
        handlePreviewClickParentMethod={onPaymentSelected}
        totalRowCount={props.payments.accountPaymentsTotalRows}
        // Properties of table
        tableTitle={"Payment History"}
        canExport={true}
        objectArray={props.payments.data}
        objectError={props.payments.error}
        fieldTitles={fieldTitles}
        fieldTypes={fieldTypes}
        fieldNames={fieldNames}
      />
      <Dialog
        open={reverseConfirmationOpen}
        onClose={handleConfirmReverseClose}
      >
        <DialogTitle>Reverse Payment?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Reverse payment?
          </DialogContentText>
          <text style={{color: 'red'}}>*** This action cannot be undone. ***</text>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmReverseClose}>Cancel</Button>
          <Button onClick={updateStateAndDB}>Submit</Button>
        </DialogActions>

      </Dialog>
      <Dialog
        open={paymentFormOpen}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={handlePaymentFormClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {props.account.data.account ? (
          <DialogTitle id="alert-dialog-slide-title">
            New Payment for Account #{props.account.data.account.accountNum}{" "}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-slide-title">
            New Payment for Account: LOADING.....{" "}
          </DialogTitle>
        )}
        <DialogContent>
          <PaymentForm
            isOpen={paymentFormOpen}
            onUpdate={() => {
              handlePaymentFormClose();
              getAllAccountPayments();
            }}
            onCancel={handlePaymentFormClose}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={paymentDetailsOpen}
        TransitionComponent={Transition}
        fullWidth={"lg"}
        maxWidth={"lg"}
        keepMounted
        onClose={handlePaymentDetailsClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          <SelectedPaymentInformationCard isOpen={paymentDetailsOpen} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
// Component Properties
AccountRecentPaymentsTable.propTypes = {
  // Store objects:
  payments: PropTypes.object.isRequired,
  account: PropTypes.object.isRequired,
  apl: PropTypes.object.isRequired,
  setPayments: PropTypes.func.isRequired,
  pushUpdateToCurrentlySelectedAccountPaymentsList: PropTypes.func.isRequired,
  getAccountBalance: PropTypes.func.isRequired,
  createNewAccountPayment: PropTypes.func.isRequired,
  getAllAccountPayments: PropTypes.func.isRequired,
  getAccountLedger: PropTypes.func.isRequired,
  setSelectedPayment: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  reverseAccountPayments: PropTypes.func.isRequired,
};

// Component State
function AccountRecentPaymentsTableState(state) {
  return {
    user: state.user,
    account: state.account,
    apl: state.apl,
    payments: state.payments,
  };
}
export default connect(AccountRecentPaymentsTableState, {
  setPayments,
  pushUpdateToCurrentlySelectedAccountPaymentsList,
  getAccountBalance,
  createNewAccountPayment,
  getAllAccountPayments,
  getAccountLedger,
  setSelectedPayment,
  reverseAccountPayments
})(AccountRecentPaymentsTable);
